;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: "banner",
    props: ['globaldata']
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _m(0)}}
__vue__options__.staticRenderFns = [function(){with(this){return _c('div',{staticClass:"banner",attrs:{"id":"banner"}},[_c('div',{staticClass:"banner-inner"},[_c('h2',[_v(" Fulcrum SPA ")]),_v(" "),_c('h4',[_v(" Simple Single Page App template by Fulcrum House ")])])])}}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-9", __vue__options__)
  } else {
    hotAPI.reload("data-v-9", __vue__options__)
  }
})()}