;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: "investor",
    props: ['globaldata'],
    data: function data() {
        return {};
    },

    methods: {},
    mounted: function mounted() {
        console.log('mounted investor');
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _m(0)}}
__vue__options__.staticRenderFns = [function(){with(this){return _c('section',{staticClass:"page",attrs:{"id":"investor"}},[_c('div',{staticClass:"banner",attrs:{"id":"investor-banner"}},[_c('h1',[_v(" Investor ")]),_v(" "),_c('p',[_v("\n            Project and business development, financial advisory and principal investing. \n        ")])]),_v(" "),_c('div',{attrs:{"id":"investor-body"}},[_c('p',{staticClass:"large"},[_v("\n            To address the finance gap not met by the mainstream banks, I work through McDaan Finance arm and project development wing to offer a unique value proposition covering three complementary service areas: project and business development, financial advisory and principal investing. \n        ")]),_v(" "),_c('p',[_v("\n            By participating across the entire project cycle and capital structure, we ensure that projects are well structured and transactions reach financial close.\n        ")]),_v(" "),_c('p',[_v("\n            I also spearhead partnership with SME in Africa seeking to expand by providing a strategic blend of finance and value added business support to allow businesses to grow. Our model represents a unique approach to giving SME’s access to markets, risk capital, skills through industry experts, financial and operational management, strategic planning, and ability to grow. We are driven by the conviction that an integrated approach is the only way to address the challenges faced by this missing segment.\n        ")])])])}}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-3", __vue__options__)
  } else {
    hotAPI.reload("data-v-3", __vue__options__)
  }
})()}