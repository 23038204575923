;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _routes = require('../../config/routes.js');

var _routes2 = _interopRequireDefault(_routes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: "Nav",
    props: ['menuState'],
    data: function data() {
        return {
            links: _routes2.default,
            showMenu: false
        };
    },

    methods: {
        scrollTo: function scrollTo(target) {
            this.$emit('scrollTo', target);
            this.toggleMenu();
        },
        toggleMenu: function toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        closeMenu: function closeMenu() {
            this.showMenu = false;
        },
        showLogin: function showLogin() {
            this.$emit('toggleLogin');
        }
    },
    computed: {
        isAuthorized: function isAuthorized() {
            return this.$store.state.authorized;
        },
        location: function location() {
            return this.$route.name;
        }
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _c('nav',{class:{'show-menu': showMenu, 'modified': menuState},attrs:{"id":'nav-'+location}},[_c('div',{class:{'toggle-menu':true},on:{"click":toggleMenu}},[_c('hr',{staticClass:"menu-bar open"}),_v(" "),_c('hr',{staticClass:"menu-bar open"})]),_v(" "),_c('a',{staticClass:"brand"},[_v(" chisara agor ")]),_v(" "),_c('div',{staticClass:"link-container",on:{"click":closeMenu}},[_c('div',{staticClass:"router-links"},_l((links),function(link){return (link.meta.render)?_c('router-link',{staticClass:"nav-link",attrs:{"to":link.path,"id":link.name + '-link'}},[_v("\n              "+_s(link.renderName)+"\n          ")]):_e()})),_v(" "),_c('hr',{staticClass:"menu-spacer"}),_v(" "),_m(0),_v(" "),_m(1)])])}}
__vue__options__.staticRenderFns = [function(){with(this){return _c('div',{staticClass:"social-media-links"},[_c('a',{attrs:{"href":""}},[_v(" fb ")]),_v(" "),_c('a',{attrs:{"href":""}},[_v(" tw ")]),_v(" "),_c('a',{attrs:{"href":""}},[_v(" yt ")]),_v(" "),_c('a',{attrs:{"href":""}},[_v(" in ")])])}},function(){with(this){return _c('div',{staticClass:"secondary-links"},[_c('a',{staticClass:"nav-link small",attrs:{"href":""}},[_v(" copyright ")]),_v(" "),_c('a',{staticClass:"nav-link small",attrs:{"href":""}},[_v(" credits ")])])}}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-12", __vue__options__)
  } else {
    hotAPI.reload("data-v-12", __vue__options__)
  }
})()}