;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: "home",
    props: ['globaldata'],
    data: function data() {
        return {
            breakdowns: [{
                header: 'About Me',
                link: 'about',
                url: '../img/bg/mob-4.jpg',
                body: "I am Anande, a wife, a mother, a business woman, a servant of God and an advocate for the development of Africa. I am a co-partner and a director of McDaan Limited, a company registered and operating in the United Kingdom and East Africa..."
            }, {
                header: 'Speaker',
                link: 'speaker',
                url: '../img/bg/mob-6.jpg',
                body: "To address the finance gap not met by the mainstream banks, I work through McDaan Finance arm and project development wing to offer a unique value proposition covering three complementary service areas: project and business development..."
            }, {
                header: 'Investor',
                link: 'investor',
                url: '../img/bg/mob-5.jpg',
                body: "I am very passionate about issues of development – the development of Sub Saharan African Countries. Despite progress made so far, Sub Saharan Africa still lags behind other developing regions..."
            }, {
                header: 'Contact',
                link: 'contact',
                email: 'contact@anandesemwenda.com',
                url: '../img/bg/mob-5.jpg',
                body: "I've had the pleasure of speaking on numerous occasions and regularly receive invitations to talk at different events. If you'd like to extend an invitation to me, please do so by emailing me at the following address"
            }],
            bannerPosition: 0,
            cycleInterval: undefined
        };
    },

    methods: {
        trimPost: function trimPost(post) {
            return post.slice(0, 90);
        },
        cyclePosition: function cyclePosition() {
            this.bannerPosition = this.bannerPosition < this.breakdowns.length - 1 ? this.bannerPosition += 1 : 0;
            console.log(this.bannerPosition);
        },
        setCycleInterval: function setCycleInterval(interval) {
            var _this = this;
            this.cycleInterval = setInterval(function () {
                _this.cyclePosition();
            }, interval);
        },
        clearCycleInterval: function clearCycleInterval() {
            clearInterval(this.cycleInterval);
        },
        isSelected: function isSelected(ind) {
            console.log(ind, this.bannerPosition);
            return this.bannerPosition == ind;
        },
        selectMe: function selectMe(ind) {
            clearInterval(this.cycleInterval);
        },
        getBlogPosts: function getBlogPosts() {},
        setUrl: function setUrl(url) {
            return 'url("' + url + '")';
        },
        something: function something() {
            console.log('will navigate to blog');
        }
    },
    mounted: function mounted() {
        console.log('mounted home');
        this.setCycleInterval(4000);
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _c('section',{staticClass:"page",attrs:{"id":"home"}},[_m(0),_v(" "),_c('section',{attrs:{"id":"site-breakdown"}},_l((breakdowns),function(bd,index){return _c('div',{staticClass:"breakdown",attrs:{"id":bd.link+'-breakdown'}},[_c('div',{staticClass:"breakdown-inner"},[_c('h2',{staticClass:"breakdown-header"},[_v(" "+_s(bd.header)+" ")]),_v(" "),_c('p',{staticClass:"breakdown-body"},[_v(" "+_s(bd.body)+" ")]),_v(" "),(!bd.email)?_c('router-link',{staticClass:"breakdown-link",attrs:{"to":{name: bd.link}}},[_v("\n                    Read More\n                ")]):_e(),_v(" "),(bd.email)?_c('p',{staticClass:"email"},[_v(" "+_s(bd.email)+" ")]):_e()],1)])}))])}}
__vue__options__.staticRenderFns = [function(){with(this){return _c('div',{staticClass:"banner",attrs:{"id":"home-page-banner"}},[_c('h1',[_v(" Anande Semwenda ")]),_v(" "),_c('h3',[_v("\n            Wife, Mother, Business woman, Servant of God, Advocate for the development of Africa, co-partner and Director at McDaan Limited\n        ")])])}}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2", __vue__options__)
  } else {
    hotAPI.reload("data-v-2", __vue__options__)
  }
})()}