;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _nav = require('./ui/nav.vue');

var _nav2 = _interopRequireDefault(_nav);

var _globaldata = require('../config/globaldata.js');

var _globaldata2 = _interopRequireDefault(_globaldata);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: "application",
    data: function data() {
        return {
            win: undefined,
            modifyNav: undefined,
            footerHeight: undefined,
            bodyHeight: undefined,
            cached: undefined,
            globaldata: _globaldata2.default,
            showBTT: false
        };
    },

    watch: {
        cached: function cached() {
            console.log('cached');
            this.bindEvents();
        }
    },
    methods: {
        cacheDom: function cacheDom() {
            this.win = window;

            this.cached = true;
        },
        bindEvents: function bindEvents() {
            var _this = this;
            this.win.addEventListener('scroll', function () {
                window.requestAnimationFrame(_this.onScroll);
            }, false);
        },
        onScroll: function onScroll() {
            this.showBTT = this.win.scrollY > this.win.innerHeight * 1.75;
            this.modifyNav = this.win.scrollY > this.win.innerHeight * 0.25;
        }
    },
    components: {
        appNav: _nav2.default
    },
    mounted: function mounted() {
        this.cacheDom();
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _c('div',{attrs:{"id":"app"}},[_c('app-nav',{attrs:{"bg":modifyNav}}),_v(" "),_c('div',{attrs:{"id":"route-container"}},[_c('router-view',{attrs:{"globaldata":globaldata}})],1),_v(" "),_c('button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#app'),expression:"'#app'"}],class:{'visible':showBTT},attrs:{"id":"back-to-top"}},[_v(" > ")])],1)}}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1", __vue__options__)
  } else {
    hotAPI.reload("data-v-1", __vue__options__)
  }
})()}