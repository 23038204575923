;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _blog = require("../../data/blog.js");

var _blog2 = _interopRequireDefault(_blog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: "blog",
    props: ['globaldata'],
    data: function data() {
        return {
            blog: undefined
        };
    },

    methods: {
        setUrl: function setUrl(url) {
            return "url(\"../img/anande/mob-" + (url + 1) + ".jpg\")";
        },
        trimPost: function trimPost(post) {
            return post.slice(0, 65);
        }
    },
    mounted: function mounted() {
        console.log('mounted blog');
        this.blog = _blog2.default.blogPosts;
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _c('section',{staticClass:"page",attrs:{"id":"blog"}},[_m(0),_v(" "),_c('section',{staticClass:"blog-post-list"},[_l((blog),function(post,index){return _c('div',{staticClass:"blog-post-container",style:({'background-image': setUrl(index)})},[_c('p',{staticClass:"blog-preview-text"},[_v("\n                "+_s(trimPost(post.content.p1))+"\n            ")]),_v(" "),_c('span',{staticClass:"blog-date",attrs:{"href":""}},[_v(" "+_s(post.date)+" ")]),_v(" "),_c('a',{staticClass:"blog-link",attrs:{"href":""}},[_v(" Read More ")])])}),_v(" "),_c('div',{staticClass:"button-container"},[_c('button',{on:{"click":function($event){$event.preventDefault();something()}}},[_v(" Load more ")])])],2)])}}
__vue__options__.staticRenderFns = [function(){with(this){return _c('section',{attrs:{"id":"blog-banner"}},[_c('h2',[_v(" Welcome to my blog ")]),_v(" "),_c('p',[_v(" Lusec Wecos Vinosec ina en damar lorem dolo set entuic ")])])}}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-7", __vue__options__)
  } else {
    hotAPI.reload("data-v-7", __vue__options__)
  }
})()}