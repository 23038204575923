'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    talks: [{
        title: 'My frist talk',
        date: '08 Aug 17',
        description: 'Nullam porta sapien sit amet pretium aliquet. Duis suscipit auctor cursus',
        bg: '../img/talks/1.JPG',
        content: {
            p1: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis',
            p2: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis'
        }
    }, {
        title: 'My Second talk',
        date: '11 Aug 17',
        description: 'Mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla',
        bg: '../img/talks/2.jpg',
        content: {
            p1: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis',
            p2: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis'
        }
    }, {
        title: 'My Third talk',
        date: '12 Aug 17',
        description: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate',
        bg: '../img/talks/3.jpg',
        content: {
            p1: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis',
            p2: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis'
        }
    }, {
        title: 'My Latest talk',
        date: '15 Aug 17',
        description: 'Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis',
        bg: '../img/talks/4.jpg',
        content: {
            p1: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis',
            p2: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis'
        }
    }]
};
