;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: "four-o-four",
    props: ['globaldata'],
    data: function data() {
        return {};
    },

    methods: {
        cacheDom: function cacheDom() {}
    },
    mounted: function mounted() {
        console.log('mounted 404');
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _m(0)}}
__vue__options__.staticRenderFns = [function(){with(this){return _c('section',{attrs:{"id":"about"}},[_c('div',{staticClass:"header"},[_c('h2',[_v(" Header ")]),_v(" "),_c('h3',[_v(" Subheader ")])]),_v(" "),_c('div',{staticClass:"content"},[_c('h4',[_v(" 404 ")]),_v(" "),_c('p',[_v(" \n            Duis porttitor libero sed tincidunt dapibus. Phasellus venenatis eu sapien in tristique. Duis elit dui, feugiat sed erat eu, volutpat vehicula lorem. Vivamus tempor, magna in dictum interdum\n        ")])])])}}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-8", __vue__options__)
  } else {
    hotAPI.reload("data-v-8", __vue__options__)
  }
})()}