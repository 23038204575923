'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

var _vuex = require('vuex');

var _vuex2 = _interopRequireDefault(_vuex);

var _routes = require('./routes');

var _routes2 = _interopRequireDefault(_routes);

var _globaldata = require('./globaldata.js');

var _globaldata2 = _interopRequireDefault(_globaldata);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vue2.default.use(_vuex2.default);

var state = {
  fixedNavBar: false,
  isMenuOpen: false,
  isHelpOpen: false,
  navRoutes: _routes2.default,
  screenPos: 0,
  pageScrollThreshold: 0,
  appLocation: 'home',
  scrollTarget: '',
  globalData: _globaldata2.default,
  authorized: true
};

var mutations = {
  setLocation: function setLocation(state, loc) {
    state.appLocation = loc;
    state.fixedNavBar = false;
  },
  resetScrollTarget: function resetScrollTarget(state) {
    state.scrollTarget = 0;
    console.log('Scroll Target has been reset');
  },


  // sendScrollTarget (state, target){
  setScrollTarget: function setScrollTarget(state, target) {
    // state.goTo = target;
    state.scrollTarget = target;
    console.log('Vuex recieved  scroll target: ', target);
  },
  setNavbarState: function setNavbarState(state, newState) {
    state.fixedNavBar = newState;
  },
  toggleNavState: function toggleNavState(state) {
    state.fixedNavBar = !state.fixedNavBar;
  },
  setMenuState: function setMenuState(state, newMenuState) {
    if (newMenuState != null) state.isMenuOpen = newMenuState;
  },
  setHelpState: function setHelpState(state, newState) {
    if (newState != null) state.isMenuOpen = newState;
  },
  setNavRoutes: function setNavRoutes(state, nav_routes) {
    if (nav_routes != null) state.navRoutes = nav_routes;
  }
};

var getters = {

  getNavState: function getNavState(state) {
    return state.fixedNavBar;
  }

};

var actions = {
  toggleNavState: function toggleNavState(_ref) {
    var commit = _ref.commit;

    commit('toggleNavState');
  },
  setMenuState: function setMenuState(_ref2) {
    var commit = _ref2.commit;

    commit('setMenuState');
  },
  setNavRoutes: function setNavRoutes(_ref3) {
    var commit = _ref3.commit;

    commit('setNavRoutes');
  }
};

var appStore = new _vuex2.default.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions
});

exports.default = appStore;
