'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    posts: {
        post_08_Aug_17: {
            title: 'Blog title 1',
            date: '08 Aug 17',
            lastEdited: '09 Aug 17',
            content: {
                blogImage: '../img/blog/img1.jpg',
                p1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel efficitur eros. Integer nec gravida nulla. Praesent erat elit, vestibulum at eleifend in, sagittis mattis sem.',
                img1: '../img/blog/img1.jpg',
                p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
                p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
            }
        },
        post_11_Aug_17: {
            title: 'Blog title 2',
            date: '11 Aug 17',
            lastEdited: '11 Aug 17',
            content: {
                blogImage: '../img/blog/img1.jpg',
                img1: '../img/blog/img4.jpg',
                p1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel efficitur eros. Integer nec gravida nulla. Praesent erat elit, vestibulum at eleifend in, sagittis mattis sem.',
                p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
                img2: '../img/blog/img5.jpg',
                p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
            }
        },
        post_15_Aug_17: {
            title: 'Blog title 3',
            date: '15 Aug 17',
            lastEdited: '15 Aug 17',
            content: {
                blogImage: '../img/blog/img1.jpg',
                img1: '../img/blog/img3.jpg',
                p1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel efficitur eros. Integer nec gravida nulla. Praesent erat elit, vestibulum at eleifend in, sagittis mattis sem.',
                p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
                p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
            }
        },
        post_17_Aug_17: {
            title: 'Blog title 4',
            date: '17 Aug 17',
            lastEdited: '17 Aug 17',
            content: {
                blogImage: '../img/blog/img1.jpg',
                p1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel efficitur eros. Integer nec gravida nulla. Praesent erat elit, vestibulum at eleifend in, sagittis mattis sem.',
                p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
                img1: '../img/blog/img6.jpg',
                p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
            }
        }
    }
};
