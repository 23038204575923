'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var globaldata = {
  company: 'Company name',
  acronym: 'CNH',
  businessReg: '01234567',
  contact: {
    emails: {
      primary: 'primary@address.co',
      owner: 'owner@address.co',
      quotes: 'quotes@address.co'
    },
    phone: {
      primary: '0123 456 789',
      mobile: '07479925044'
    },
    socialMedia: {
      facebook: 'https://www.facebook.com/company',
      linkedIn: 'https://www.linkedin.com/company',
      twitter: 'https://www.twitter.com/company',
      codepen: 'https://www.codepen.io/company',
      behance: 'https://www.behance.com/company'
    }
  },
  address: {
    line1: '99 Palace Road',
    city: 'City',
    borough: 'Borough',
    postCode: 'PO0 C00'
  }
};

exports.default = globaldata;
