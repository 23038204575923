;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    name: "videos",
    props: ['globaldata'],
    data: function data() {
        return {};
    },

    methods: {
        something: function something() {
            console.log('something');
        }
    },
    mounted: function mounted() {
        console.log('mounted videos');
    }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function(){with(this){return _c('section',{staticClass:"page",attrs:{"id":"videos"}},[_c('div',{staticClass:"videos-container"},[_l((4),function(vid){return _c('div',{staticClass:"video-container"},[_m(0,true),_v(" "),_m(1,true)])}),_v(" "),_c('div',{staticClass:"button-container"},[_c('button',{on:{"click":function($event){$event.preventDefault();something()}}},[_v(" Load More ")])])],2)])}}
__vue__options__.staticRenderFns = [function(){with(this){return _c('div',{staticClass:"video-frame"},[_c('i',{staticClass:"play-icon ion-play"})])}},function(){with(this){return _c('div',{staticClass:"video-details"},[_c('h2',[_v(" Video Title ")]),_v(" "),_c('p',[_v(" \n                    Maecenas dapibus nibh vitae nisi fringilla, in vehicula mauris maximus. Aliquam suscipit tempor mattis. Fusce aliquet finibus tellus. Integer ultrices dapibus mi eget euismod\n                ")])])}}]
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-5", __vue__options__)
  } else {
    hotAPI.reload("data-v-5", __vue__options__)
  }
})()}