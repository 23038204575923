'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    blogPosts: [{
        title: 'Blog title 1',
        date: '08 Aug 17',
        lastEdited: '09 Aug 17',
        content: {
            blogImage: '../img/blog/1.jpg',
            p1: 'Maecenas molestie accumsan mauris tincidunt tristique. Donec congue laoreet lectus nec vulputate. Suspendisse nec quam quis nulla pulvinar finibus. Suspendisse pretium leo urna, eget sagittis ex mollis',
            img1: '../img/blog/img1.jpg',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }, {
        title: 'Blog title 2',
        date: '11 Aug 17',
        lastEdited: '11 Aug 17',
        content: {
            blogImage: '../img/blog/2.jpg',
            img1: '../img/blog/img4.jpg',
            p1: 'Nullam metus erat, finibus sit amet porta a, semper nec nisi. Integer eget luctus libero. Integer condimentum quam id nibh sodales accumsan. Ut eros metus, scelerisque id molestie vel, condimentum ac sapien',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            img2: '../img/blog/img5.jpg',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }, {
        title: 'Blog title 3',
        date: '15 Aug 17',
        lastEdited: '15 Aug 17',
        content: {
            blogImage: '../img/blog/7.jpg',
            img1: '../img/blog/img3.jpg',
            p1: 'Sed interdum leo vel orci dictum, id malesuada turpis fringilla. Etiam auctor consequat quam, laoreet luctus orci viverra vitae. Sed ut tempus dolor. Nunc nisl dolor, congue sed gravida eu, tincidunt id nibh',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }, {
        title: 'Blog title 4',
        date: '17 Aug 17',
        lastEdited: '17 Aug 17',
        content: {
            blogImage: '../img/blog/4.jpg',
            p1: 'Phasellus bibendum, mi eu euismod luctus, enim ligula viverra mi, et gravida justo odio eu mi. Etiam eleifend lorem bibendum varius efficitur. Cras aliquet libero nulla, sit amet dapibus velit ultrices sed. Curabitur maximus felis et magna ultrices, nec malesuada ex lacinia',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            img1: '../img/blog/img6.jpg',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }, {
        title: 'Blog title 5',
        date: '08 May 17',
        lastEdited: false,
        content: {
            blogImage: '../img/blog/5.jpg',
            p1: 'Vestibulum vel scelerisque lectus, at accumsan nisl. Nulla tincidunt nibh vel pretium iaculis. Suspendisse quis elementum libero. Aliquam vel enim eget arcu congue efficitur et quis urna. Aliquam mollis eu augue quis iaculis. Ut sodales sapien quis enim imperdiet, vitae maximus justo dictum',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            img1: '../img/blog/img6.jpg',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }, {
        title: 'Blog title 6',
        date: '22 May 17',
        lastEdited: false,
        content: {
            blogImage: '../img/blog/6.jpg',
            p1: 'Pellentesque ut tortor dignissim dolor eleifend hendrerit. Mauris eu sodales magna, id faucibus ex. Vestibulum efficitur vel metus nec auctor. Quisque efficitur aliquam lacus, vel molestie tortor ultrices vitae',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            img1: '../img/blog/img6.jpg',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }, {
        title: 'Blog title 7',
        date: '25 May 17',
        lastEdited: false,
        content: {
            blogImage: '../img/blog/8.jpg',
            p1: 'Vestibulum vel elementum ante. Maecenas non massa sit amet leo auctor malesuada quis sit amet lectus. Aenean id gravida ligula, sit amet rhoncus odio. Etiam eget tempor elit',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            img1: '../img/blog/img6.jpg',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }, {
        title: 'Blog title 5',
        date: '2 Jun 17',
        lastEdited: false,
        content: {
            blogImage: '../img/blog/9.jpg',
            p1: 'Mauris maximus elit a malesuada laoreet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent dui mi, auctor id metus sit amet, hendrerit pulvinar augue',
            p2: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu',
            img1: '../img/blog/img6.jpg',
            p3: 'Nam efficitur lectus vel tortor malesuada, nec pharetra mi porttitor. Quisque maximus risus et maximus laoreet. Proin luctus enim purus, at rutrum nulla pharetra vel. Nunc id semper arcu'
        }
    }]
};
