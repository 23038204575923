'use strict';

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

var _App = require('components/App.vue');

var _App2 = _interopRequireDefault(_App);

var _veeValidate = require('vee-validate');

var _veeValidate2 = _interopRequireDefault(_veeValidate);

var _vueResource = require('vue-resource');

var _vueResource2 = _interopRequireDefault(_vueResource);

var _vueScrollto = require('vue-scrollto');

var _vueScrollto2 = _interopRequireDefault(_vueScrollto);

var _router = require('./config/router.js');

var _router2 = _interopRequireDefault(_router);

var _store = require('./config/store.js');

var _store2 = _interopRequireDefault(_store);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vue2.default.use(_vueResource2.default);
_vue2.default.use(_veeValidate2.default);
_vue2.default.use(_vueScrollto2.default, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    cancelable: true,
    onDone: false,
    onCancel: false,
    x: true,
    y: true
});

new _vue2.default({
    el: '#app',
    router: _router2.default,
    store: _store2.default,
    render: function render(h) {
        return h(_App2.default);
    }
});
