"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _home = require("../components/pages/home.vue");

var _home2 = _interopRequireDefault(_home);

var _about = require("../components/pages/about.vue");

var _about2 = _interopRequireDefault(_about);

var _speaker = require("../components/pages/speaker.vue");

var _speaker2 = _interopRequireDefault(_speaker);

var _investor = require("../components/pages/investor.vue");

var _investor2 = _interopRequireDefault(_investor);

var _videos = require("../components/pages/videos.vue");

var _videos2 = _interopRequireDefault(_videos);

var _blog = require("../components/pages/blog.vue");

var _blog2 = _interopRequireDefault(_blog);

var _ = require("../components/pages/404.vue");

var _2 = _interopRequireDefault(_);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var appRoutes = [{
	path: "/",
	// redirects root address "www.website.com/" to your music page
	name: 'root',
	redirect: '/home',
	renderName: 'Home',
	meta: {
		render: false,
		scrollToTop: false
	}
}, {
	path: "/home",
	name: 'home',
	renderName: 'Home',
	component: _home2.default,
	meta: {
		render: true,
		scrollToTop: true
	}
}, {
	path: "/about",
	name: 'about',
	renderName: 'About',
	component: _about2.default,
	meta: {
		render: true,
		scrollToTop: true
	}
}, {
	path: "/speaker",
	name: 'speaker',
	renderName: 'Speaker',
	component: _speaker2.default,
	meta: {
		render: true,
		scrollToTop: true
	}
}, {
	path: "/investor",
	name: 'investor',
	renderName: 'Investor',
	component: _investor2.default,
	meta: {
		render: true,
		scrollToTop: true
	}
}, {
	path: "/videos",
	name: 'videos',
	renderName: 'Videos',
	component: _videos2.default,
	meta: {
		render: false,
		scrollToTop: true
	}
}, {
	path: '/blog',
	name: 'blog',
	renderName: 'Blog',
	component: _blog2.default,
	meta: {
		render: false,
		scrollTop: true
	}
}, {
	path: "/page-not-found",
	name: 'pageNotFound',
	renderName: '404!',
	component: _2.default,
	meta: {
		render: false,
		scrollToTop: true
	}
}, {
	path: "*",
	name: 'anythingElse',
	renderName: 'redirect',
	redirect: { name: 'pageNotFound' },
	meta: {
		render: false
	}
}];
// import Footer from "../components/ui/Footer.vue";
exports.default = appRoutes;
