'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

var _vueRouter = require('vue-router');

var _vueRouter2 = _interopRequireDefault(_vueRouter);

var _routes = require('./routes.js');

var _routes2 = _interopRequireDefault(_routes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vue2.default.use(_vueRouter2.default);

var AppRouter = new _vueRouter2.default({
  mode: 'history',
  base: '/',
  routes: _routes2.default
});

AppRouter.beforeEach(function (to, from, next) {
  // removeEventListeners?

  window.scrollTo(0, 0);
  next();
});

exports.default = AppRouter;
